import request from '@index/utils/request'

export function handlePageQuery(data){//分页查询赛程总表
 return request({
    url: 'matchsumtable/pageMatchSumTable',
    method: 'post',
    data
  })
}


export function handlePageQueryMyMatchSumTable(data){//分页查询我的赛程总表
 return request({
    url: 'matchsumtable/pageQueryMyMatchSumTable',
    method: 'post',
    data
  })
}


export function handleDeleteSumTable(matchId){//删除赛程总表
 return request({
    url: 'matchsumtable/deleteSumTable',
    method: 'post',
    params:{matchId:matchId}
  })
}




export function handleMatchSumDownload(matchId) { //参赛方下载成绩表
  return request({
    url: 'matchsumtable/download',
    method: 'post',
	responseType: 'blob',
   params:{matchId:matchId}
  })
}